<template>
   <div class="FAQHolder pb-6">
       <Slider />
       <Breadcrumbs :page="page" :items="breadcrumbs" color="#f4f5f7" />

         <v-card tile flat v-if="loadingData">
                <v-card-text align="center" justify="center">
                     <v-progress-circular
                        :size="50"
                        :width="5"
                        color="purple"
                        indeterminate
                        class="mr-2"
                        ></v-progress-circular>
                        Loading FAQs ...

                </v-card-text>
            </v-card>
        <v-flex xs10 sm10 offset-sm1 class="" v-else>

             <v-card flat tile color="" width="90%" class="transparent main-content-products">
                <v-card-title primary-title class="justify-center">
                        <h3 class="zamu-heading transact-heading slogan-heading">{{ category }} </h3><br />
                        <v-card tile flat :color="`${zamuGrey}`" height="1" class="justify-center transact-underline mt-3" v-if="!loadingData">
                            <v-card-text class="transact-divider divider-purple"></v-card-text>
                            <v-card-text class="transact-divider divider-yellow"> </v-card-text>
                        </v-card>
                </v-card-title>
                   <v-card-text align="center" justify="center">
                        <p class="transact-text product-text">{{ description }}</p>
                    </v-card-text>
             </v-card>
             <v-card flat tile v-if="postData === null || postData.length === 0">
                 <v-card-text align="center" justify="center">

                     No FAQ Articles in this category

                 </v-card-text>

             </v-card>

                <v-card  class="mx-auto" v-else >
                    <v-list two-line>
                    <v-list-item-group  v-model="selected"   active-class="pink--text"  multiple >
                        <template v-for="(item, index) in postData">
                        <v-list-item :key="`title_${index}`" @click="singlePost(item.id)">
                            <v-list-item-content>
                                <v-list-item-title class="faq-title">{{ item.title}}</v-list-item-title>
                                <v-list-item-subtitle  class="text--primary"  v-text="item.headline"></v-list-item-subtitle>

                                <v-list-item-subtitle v-html="item.snippet"></v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action>
                                <v-list-item-action-text ><strong>Last Updated on: </strong>  <span class="ml-2">{{ formatDate(item.updated_at , 'MMM DD, YYYY') }}</span></v-list-item-action-text>
                               
                            </v-list-item-action>
                        </v-list-item>
                        <v-divider
                            v-if="index < postData.length - 1"
                            :key="index"
                        ></v-divider>
                        </template>
                    </v-list-item-group>
                    </v-list>
                </v-card>

                   <v-card tile flat color="#f4f5f7" v-if="postData !== null">
                   <v-card-text  class="d-flex justify-space-between">
                        <v-btn-toggle v-if="currentPage > 1" >
                     <v-btn :color="`${zamuYellow}`">
                        <v-icon>mdi-arrow-left</v-icon>
                      </v-btn>
                      <v-btn depressed dark :color="`${zamuPurple2}`" @click="showPrevious()">
                        Show Previous
                      </v-btn>
                  </v-btn-toggle>
                  <v-btn-toggle v-if="currentPage <  pagination.lastPage" >
                      <v-btn depressed dark :color="`${zamuPurple2}`" @click="showMore()">
                        Show More
                      </v-btn>

                      <v-btn :color="`${zamuYellow}`">
                        <v-icon>mdi-arrow-right</v-icon>
                      </v-btn>
                  </v-btn-toggle>
                </v-card-text>
            </v-card>
        </v-flex>
   </div>
</template>

<script>
import coreMixin from '@/mixins/coreMixin';
import colorMixin from '@/mixins/colorMixin';

export default {
    name: 'FAQ-List',
    components: {
      Slider: () => import('./slider'),
      Breadcrumbs: () => import('../../layout/Breadcrumb.vue'),

     },
    mixins: [coreMixin, colorMixin],

    data () {
       return {
            page: 'faqs',
            categoryID: null,
            category: '',
            description: '',
            loadingData: false,
            selected: [2],
            posts: null,
            currentPage: 1,
       };
    },
    computed: {
        postData () {
            return this.posts !== null ? this.posts.data : null;
        },
        pagination () {
            let data = null;
            if (this.posts !== null) {
                 data = {
                    total: this.posts.total,
                    perPage: this.posts.perPage,
                    page: this.posts.page,
                    lastPage: this.posts.lastPage,
                };
            }
            return data;

        },
        breadcrumbs() {
            const data = [
                {
                text: 'All FAQs',
                disabled: false,
                href: '/faqs',
                },
              
                {
                text: `${this.category}`,
                disabled: true,
                href: `${this.page}`,
                },
            ];
            return data;

        }

    },
    watch: {
        async currentPage() {
             this.posts = await this.retrieveCategoryPosts();

        },
    },

   async  mounted() {
        this.categoryID = this.$route.params.id;
        this.categoryData = await this.retrieveCategory( this.categoryID);
        this.category = this.categoryData.category;
        this.description = this.categoryData.description;
        this.posts = await this.retrieveCategoryPosts();

    },
    methods: {
        async retrieveCategoryPosts() {
            this.loadingData = true;

            const payload = {
                page: this.currentPage,
                category: this.categoryID,
            };
        
            const endpoint = `FAQs/category-posts`;
        
            const fullPayload = {
                app: this.apiUrl,
                params: payload,
                endpoint,
            };
        
            try {
                const response = await this.axiosPostRequest(fullPayload);
                const posts = response.status === 200 ? response.data : null;
                this.loadingData = false;

                return posts;
            } catch (error) {
                this.loadingData = false;
                return null;
                
            }
     },
       singlePost(id) {
        this.$router.push({ name: 'single-FAQ', params: { id: id } })
      },
       showMore() {
            return this.currentPage = this.currentPage + 1;
        },
         showPrevious() {
            return this.currentPage = this.currentPage - 1;
        },
    },

}
</script>

<style scoped>
.slogan-heading {
    font-size: 31px !important;
    text-transform: capitalize !important;
    width: 100%;
    text-align: center;
}
.faq-title {
    color: rgba(0, 0, 0, 0.87) !important;
}
</style>